/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback } from 'react'
import { Button, Col, Drawer, Empty, Form, Input, message, Modal, Radio, Row, Select, Spin, Table } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import MyStep from '../../wo-management/stepAndSignature/MyStep'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { addJob, getAllJob, checkIsCurrentJob, getCurrentJobNo, updateJobStatus, cancelAllJobs } from './service'
import { useLocation, useParams } from 'react-router-dom'
import { Job } from './model'
import { assignees, assignmentGroups, findWorkOrderId, getAssignmentUser, ticketRunningNumber } from '../../wo-management'
import { sendLineNotify } from '../../../authorization-module/role-and-permissions/service'
import { decryptDataVspace } from '../../../common-misc'
import { FormComponentProps } from 'antd/lib/form'
interface Param {
    getItemJob: Function,
    woId?: string,
    permission: boolean,
    roleId: number,
    assignedTo: assignees[],
    vSpaceIncidentNo: string,
    isAssetNotNull: boolean
    setAssignedValue?: Function,
    assignedValue: string[]
}

const mapStateToProps = (state: StoreState) => {
    return {
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllJob: (woId?: string) => Promise<Job[] | undefined>,
}
const { Option } = Select
type Props = Param & DispatchProps & StateProps & FormComponentProps
let isUpdate: boolean = false
const RelateJob: React.FC<Props> = (props: Props) => {
    const dataVspace = decryptDataVspace()
    let email: string
    if (typeof dataVspace === 'object') {
        email = dataVspace.email
    }
    const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false)
    const [isCreateJob, setIsCreateJob] = useState<boolean>(false)
    const [Template, setTemplate] = useState<number>(7)
    const [data, setData] = useState<Job[] | undefined>([])
    const [woId, setWoId] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSpinning, setIsSpinning] = useState<boolean>(false)
    const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false)
    const [username, setUsername] = useState<string>('')
    const [currentJobNo, setCurrentJobNo] = useState<string>('')
    const [assignmentGroup, setAssignmentGroup] = useState<string | null>()
    const [assignedTo, setAssignedTo] = useState<string>()
    const [assignedToForOption, setAssignedToForOption] = useState<assignees[]>([])
    const [User, setUser] = useState<assignmentGroups[]>([])
    const [isShowCreate, setIsShowCreate] = useState<boolean>(true)
    const [isShowChooseTemplate, setIsShowChooseTemplate] = useState<boolean>(true)
    const { id } = useParams()
    const location = useLocation()
    const { getFieldDecorator, isFieldValidating, getFieldValue } = props.form
    const [isLoadingCreateJob, setIsLoadingCreateJob] = useState<boolean>(false)
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])
    const [isHaveJobs, setisHaveJobs] = useState<boolean>(false)

    useEffect(() => {
        fetchAssignmentUser()
        props.getAllJob(id).then((data) => {
            setisHaveJobs(data?.length !== 0 ? true : false)
            setData(data)
            initialData(data)
        })
    }, [])

    useEffect(() => {
        setUsername(email)
        const jobData = {
            lastModifiedBy: email,
            jobStatus: ''
        }
        if (id) {
            const idWO = id || ''
            setWoId(idWO)
            const job = data?.find((job) => {
                if (job.jobNo === currentJobNo) {
                    return job
                }
            })
            if (job && job.jobStatus === 'Created' && job.templateNo !== 8) {
                jobData.jobStatus = job.jobTopic === 'Approve' ? 'Awaiting Approval' : 'Open'
                updateJobStatus(job.id!!, jobData).then(() => {
                    props.getAllJob(idWO).then((data) => {
                        setData(data)
                        initialData(data)
                    }).catch((err) => message.error(`Failed loading initial job data. ${err}`))
                        .finally(() => {
                            if (jobData.jobStatus === 'Awaiting Approval') {
                                findWorkOrderId(parseInt(woId)).then((res) => {
                                    const assignedTo = props.assignedTo.find((assign) => assign.email === res.assignee)?.name
                                    sendLineNotify(`
                                    รอการ Approve
                                    Assigned to : ${assignedTo || '-'} <${res.assignee ? res.assignee : '-'}>
                                    Order ID: ${res.number}
                                    Related Ticket Number: ${props.vSpaceIncidentNo ? props.vSpaceIncidentNo : '-'}
                                    Assigned by: ${email}
                                    Topic: ${res.topic}
                                    Location:${res.contactUserLocation}
                                    ${window.location.href}`, 'Awaiting Approval')
                                })
                            }
                        })
                })
            } else {
                props.getAllJob(idWO).then((data) => {
                    if (data) {
                        if (data.length > 0) {
                            setData(data)
                            initialData(data)
                        }
                    }
                    const istemplate8 = data?.some((it) => it.templateNo === 8 && it.jobStatus === 'Created')
                    if (istemplate8) {
                        const promiseAll2: Promise<Job | void>[] = []
                        let ConvertDataJob: Job[] = []
                        const jobfilter = data?.filter((it) => it.templateNo === 8 && it.jobStatus === 'Created')
                        ConvertDataJob = (jobfilter || []).map((it) => {
                            return { ...it, jobStatus: 'Open' }
                        })
                        const newData = data?.map((it) => {
                            if (it.templateNo === 8 && it.jobStatus === 'Created') {
                                return { ...it, jobStatus: 'Open' }
                            } else {
                                return it
                            }
                        })
                        setData(newData)
                        if (ConvertDataJob.length !== 0 && !isUpdate) {
                            isUpdate = true
                            ConvertDataJob.forEach((job) => {
                                if (job.id) {
                                    updateJobStatus(job.id?.toString(), job).catch((err) => {
                                        message.error(`You have unSuccessfully save the data. ${err}`)
                                    })
                                }
                            })
                            if (promiseAll2.length !== 0) {
                                Promise.all(promiseAll2).finally(() => {
                                    forceUpdate()
                                })
                            }
                        }
                    }
                }).catch((err) => message.error(`Failed loading initial job data. ${err}`))
            }
        }
    }, [location.search, isChangeStatus, currentJobNo])

    useEffect(() => {
        if (location.pathname.includes('/WoFormEdit') || location.pathname === '/WoList') {
            if (props.job.length > 0) {
                setData(props.job)
            }
        }
    }, [props.job])

    useEffect(() => {
        setCurrentJobNo(getCurrentJobNo(data!!))
    }, [data])

    useEffect(() => {
        if (User) {
            (User || []).map((data) => {
                if (data.name === getFieldValue('group')) {
                    setAssignedToForOption(data.assignees.filter(it => it.name?.length !== 0))
                }
            })
        }
        if (!getFieldValue('group')) {
            setAssignedToForOption([])
        }
    }, [getFieldValue('group'), User])

    const fetchAssignmentUser = () => {
        getAssignmentUser().then((res) => {
            setUser(res)
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const showCancel = (items: Job[]) => {
        if (items) {
            if (items?.length > 0) {
                if (items[0].jobStatus !== 'Closed' && items[0].jobStatus !== 'Cancelled' && id && items[0].createdDate) {
                    return <Button type={'primary'} onClick={cancelJobs} disabled={props.permission}>Cancel</Button>
                }
            }
        }
        return null
    }

    const cancelJobs = () => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to cancel jobs ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                const ids: string[] = []
                data?.map((j) => {
                    if (j.id) {
                        ids.push(j.id)
                    }
                })
                setTemplate(7)
                cancelAllJobs(ids).then((res) => {
                    setData(res)
                    props.getItemJob(res)
                }).catch((err) => message.error(`Failed cancel job. ${err}`))
                setisHaveJobs(false)
            }
        })
    }

    const cancelJob = (item: Job) => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to cancel jobs ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                if (item.templateNo === 8 && item.jobStatus !== 'Created') {
                    cancelAllJobs([item.id?.toString()!!]).then((res) => {
                        setData(res)
                        props.getItemJob(res)
                    }).catch((err) => message.error(`Failed cancel job. ${err}`))
                } else {
                    const filterData = data?.filter((res) => res.jobNo !== item.jobNo)
                    setData(filterData)
                    if (filterData?.length === 0) {
                        setIsShowChooseTemplate(true)
                    }
                    props.getItemJob(filterData)
                }
            }
        })
    }

    const showChooseTemplate = () => {
        let isShow = true
        if (id) {
            if ((data || []).length > 0) {
                // @ts-ignore
                if (data[0].createdDate) {
                    isShow = false
                }
            }
        } else {
            isShow = true
        }
        return isShow && isShowChooseTemplate ? !props.permission ? <a href="#" onClick={() => setVisibleDrawer(!visibleDrawer)} >Choose Template</a> : null : null
    }

    const showCreateTemplate = () => {
        let isShow = true
        if (id) {
            if ((data || []).length > 0) {
                // @ts-ignore
                if (data[0].templateNo !== 8) {
                    isShow = false
                }
            }
        } else {
            isShow = true
        }
        return isShow && isShowCreate ? !props.permission ? <a href="#" onClick={() => setIsCreateJob(!isCreateJob)} >Create Job</a> : null : null
    }

    const onSubmitCreateJob = () => {
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsSpinning(true)
                setIsLoadingCreateJob(true)
                setIsShowChooseTemplate(false)
                const jobNumber = await ticketRunningNumber('Job')
                const newJob: Job = {
                    woId: props.woId!!,
                    jobNo: jobNumber,
                    jobTopic: values.jobTopic,
                    jobStatus: 'Created',
                    templateNo: 8,
                    createdBy: username,
                    lastModifiedBy: email,
                    supportTeam: getFieldValue('group'),
                    assignee: getFieldValue('to')
                }
                data?.push(newJob)
                setData(data)
                setIsCreateJob(false)
                setIsSpinning(false)
                setIsLoadingCreateJob(false)
                setTemplate(8)
                props.getItemJob(data)
                props.form.resetFields()
                setAssignedToForOption([])
                setAssignedTo(undefined)
                setAssignmentGroup(null)
            }
        })
    }

    const initialData = (it) => {
        (it || []).map((it) => {
            if (it.templateNo) {
                setTemplate(it.templateNo)
            } else {
                setTemplate(7)
            }
        })
    }

    const handleChangeStatus = (change: boolean) => {
        setCurrentJobNo(getCurrentJobNo(data!!))
        setIsChangeStatus(change)
    }

    const onClose = () => {
        setVisibleDrawer(false)
        if (Template === 7) {
            setData(undefined)
        }
    }

    const onSubmit = async () => {
        const jobTopic = getTopicFromTemplate(Template)
        const listOfItem: Job[] = []
        for (const topic of jobTopic) {
            setIsSpinning(true)
            setIsLoading(true)
            const jobNumber = await ticketRunningNumber('Job')
            if (jobNumber) {
                listOfItem.push({
                    templateNo: Template,
                    woId: woId,
                    jobNo: jobNumber,
                    jobTopic: topic,
                    jobStatus: 'Created',
                    createdBy: username,
                    lastModifiedBy: email,
                    supportTeam: assignmentGroup,
                    assignee: assignedTo
                })
            }
        }
        setIsSpinning(false)
        setVisibleDrawer(false)
        setIsLoading(false)
        setData(listOfItem)
        if (Template !== 7) {
            setIsShowCreate(false)
        }
        props.getItemJob(listOfItem)
    }

    const getTopicFromTemplate = (template) => {
        let arrTopic: string[]
        switch (template) {
        case 1:
            arrTopic = ['Replace', 'Repair', 'Return']
            break
        case 2:
            arrTopic = ['Replace', 'Collect', 'Approve', 'Repair', 'QC', 'Return']
            break
        case 3:
            arrTopic = ['Replace', 'Return']
            break
        case 4:
            arrTopic = ['Install']
            break
        case 5:
            arrTopic = ['Replace', 'Collect']
            break
        case 6:
            arrTopic = ['Collect']
            break
        default:
            arrTopic = []
        }
        return arrTopic
    }

    const ChooseTemplate = (e: RadioChangeEvent) => {
        if (Template !== e.target.value) {
            setAssignedTo(undefined)
            setAssignmentGroup(null)
        }
        setTemplate(e.target.value)
        if (e.target.value === 7) {
            setIsShowCreate(true)
        }
        setData(undefined)
    }

    const updateAssignGroupAndAssignTo = (listOfItemWithAssignment, jobNo) => {
        if (id) {
            const updateJob = listOfItemWithAssignment.find((it) => it?.jobNo === jobNo)
            if (updateJob?.jobStatus !== 'Created' || location.pathname.includes('/WoFormEdit')) {
                if (updateJob?.id) {
                    updateJobStatus(updateJob?.id?.toString(), { ...updateJob }).then(() => {
                        if (listOfItemWithAssignment.length !== 0) {
                            props.getItemJob(listOfItemWithAssignment)
                        }
                    }).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    })
                }
            }
        }
        if (listOfItemWithAssignment.length !== 0) {
            props.getItemJob(listOfItemWithAssignment)
        }
    }

    const getAssignmentGroupAndAssignToFromMyStep = (jobNo, group, assign) => {
        setAssignmentGroup(group || null)
        const listOfItemWithAssignment = (data || []).map((res) => {
            if (jobNo === res.jobNo) {
                return { ...res, supportTeam: group, assignee: assign }
            } else {
                return res
            }
        })
        setData(listOfItemWithAssignment as Job[])
        updateAssignGroupAndAssignTo(listOfItemWithAssignment, jobNo)
        if (props.setAssignedValue) {
            props.setAssignedValue([group,assign])
        }
    }


    const updateAssignedNextJob = (jobId, group, assign) => {
        const listOfItemWithAssignment = (data || []).map((res) => {
            if (jobId === res.id) {
                return { ...res, supportTeam: group, assignee: assign }
            } else {
                return res
            }
        })
        if (props.setAssignedValue) {
            props.setAssignedValue([group,assign])
        }
        setData(listOfItemWithAssignment as Job[])
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'id',
            key: 'id',
            width: '7%',
            // eslint-disable-next-line react/display-name
            render: (id, data, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                )
            }
        },
        {
            title: 'Job ID',
            dataIndex: 'jobNo',
            key: 'jobNo',
            width: '20%'
        },
        {
            title: 'Topic',
            dataIndex: 'jobTopic',
            key: 'jobTopic',
            width: '58%'
        },
        {
            title: 'Job Status',
            dataIndex: 'jobStatus',
            key: 'jobStatus',
            width: '15%'
        }
    ]

    const expandedRowRender = (expandedRow, index) => {
        const currentJob: boolean = checkIsCurrentJob(index, data!!, expandedRow)
        return <div style={{ padding: 10, backgroundColor: '#FFF2E8' }}>
            {/* eslint-disable-next-line react/prop-types */}
            <MyStep
                woId={props.woId as string || ''}
                isDirection={false}
                isSignature={!(Template === 7 || Template === 6)}
                job={expandedRow}
                isCurrentJob={currentJob}
                handleChangeStatus={handleChangeStatus}
                username={username}
                isStatus={isChangeStatus}
                roleId={props.roleId}
                vSpaceIncidentNo={props.vSpaceIncidentNo}
                getDataFromMyStep={getAssignmentGroupAndAssignToFromMyStep}
                User={User}
                isAssetNotNull = {props.isAssetNotNull}
                updateAssignedNextJob={updateAssignedNextJob}
                assignedValue={props.assignedValue}
            />
            <br />
        </div>
    }

    const cancelCreateJob = () => {
        setIsCreateJob(false)
        props.form.resetFields()
        setAssignedToForOption([])
    }

    return (
        <>
            <Row>
                <Col span={12} >
                    {showCreateTemplate()}
                    <Drawer
                        title="Create Job"
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        width={350}
                        visible={isCreateJob}
                    >
                        <Form>
                            <Form.Item label="Job Topic">
                                {getFieldDecorator('jobTopic', {
                                    rules: [{
                                        required: true, message: 'Job Topic is required'
                                    }]
                                })(
                                    <Input placeholder="Job Topic" style={{ width: '100%' }} maxLength={255} />
                                )}
                            </Form.Item>
                            <Form.Item label="Assignment group">
                                {getFieldDecorator('group', {
                                    rules: [{
                                        required: true, message: 'Assignment group is required'
                                    }]
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select an Assignment group"
                                        allowClear
                                        onChange={() => {
                                            props.form.setFieldsValue({
                                                to: undefined
                                            })
                                        }}
                                    >
                                        {User.map((it, index) => {
                                            return <Option value={it.name} key={index}>{it.name}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Assigned to">
                                {getFieldDecorator('to', {
                                    rules: [{
                                        required: false
                                    }]
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select an Assigned to"
                                        allowClear
                                    >
                                        {(assignedToForOption || []).map((it, index) => {
                                            return <Option value={it.email} key={index}>{it.name}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            <div style={{ bottom: 0, right: 0, margin: '5%', textAlign: 'right' }}>
                                <Button htmlType="button" style={{ marginRight: 10 }} onClick={() => cancelCreateJob()}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    loading={isLoadingCreateJob}
                                    onClick={() => onSubmitCreateJob()}
                                    disabled={isFieldValidating('jobTopic') && isFieldValidating('group')}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                </Col>
                <Col span={12} style={{ textAlign: 'end' }}>
                    {showChooseTemplate()}
                    <Drawer
                        title="Template"
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        width={350}
                        visible={visibleDrawer}
                    >
                        <Radio.Group onChange={(e) => ChooseTemplate(e)} value={Template}>
                            <Radio value={1} style={{ width: '100%' }}>
                                <span className='sub-title'>HW Issue & Can’t Fix (HW In Warranty)</span> <br />
                                <span className='Desc_text_gray'>Replace, Repair, Return</span>
                            </Radio>
                            <Radio value={2} style={{ width: '100%' }}>
                                <span className='sub-title'>HW Issue & Can’t Fix (HW No Warranty)</span> <br />
                                <span className='Desc_text_gray'>Replace, Collect, Approve, Repair, QC, Return</span>
                            </Radio>
                            <Radio value={3} style={{ width: '100%' }}>
                                <span className='sub-title'>Borrow HW</span> <br />
                                <span className='Desc_text_gray'>Replace, Return</span>
                            </Radio>
                            <Radio value={4} style={{ width: '100%' }}>
                                <span className='sub-title'>Install New HW</span> <br />
                                <span className='Desc_text_gray'>Install</span>
                            </Radio>
                            <Radio value={5} style={{ width: '100%' }}>
                                <span className='sub-title'>Replace HW</span> <br />
                                <span className='Desc_text_gray'>Replace, Collect</span>
                            </Radio>
                            <Radio value={6} style={{ width: '100%' }}>
                                <span className='sub-title'>Return HW / Move HW: Different Building</span> <br />
                                <span className='Desc_text_gray'>Collect</span>
                            </Radio>
                            <Radio value={7} style={{ width: '100%' }}>
                                <span className='sub-title'>None</span>
                            </Radio>
                        </Radio.Group>

                        <div style={{ bottom: 0, right: 0, margin: '5%', textAlign: 'right' }}>
                            <Button htmlType="button"
                                style={{ marginRight: 10 }} onClick={() => onClose()}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" onClick={() => onSubmit()} loading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </Drawer>
                </Col>
            </Row>
            <br />
            {Template === 7 && !visibleDrawer ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                : Template === 8 ? <div>
                    {data?.map((it, index) => {
                        return <div style={{ padding: 10, backgroundColor: '#F2F2F2', marginBottom: 10 }} key={index}>
                            <Row gutter={[0, 8]}>
                                <Col xs={24} sm={24} style={{ textAlign: 'right' }}>
                                    <Button type={'primary'} onClick={() => cancelJob(it)} disabled={props.permission || it.jobStatus === 'Closed' || it.jobStatus === 'Cancelled'}>Cancel</Button>
                                </Col>
                            </Row>
                            <br />
                            <Spin spinning={isSpinning}>
                                <div style={{ padding: 10, overflow: 'auto' }}>
                                    <Table
                                        dataSource={[it]}
                                        columns={columns}
                                        rowKey='jobNo'
                                        expandedRowRender={props.permission || !isHaveJobs ? undefined : expandedRowRender}
                                        pagination={false}
                                    />
                                </div>
                            </Spin>
                        </div>
                    })}
                </div>
                    : <div style={{ padding: 10, backgroundColor: '#F2F2F2' }}>
                        <Row gutter={[0, 8]}>
                            <Col xs={24} sm={12}>
                                {Template === 1 ? <>
                                    <span className='sub-title'>HW Issue & Can’t Fix (HW In Warranty)</span> <br />
                                    <span style={{ color: '#8C8C8C' }}>Replace, Repair, Return</span>
                                </> : null}
                                {Template === 2 ? <>
                                    <span className='sub-title'>HW Issue & Can’t Fix (HW No Warranty)</span> <br />
                                    <span style={{ color: '#8C8C8C' }}>Replace, Collect, Approve, Repair, QC, Return</span>
                                </> : null}
                                {Template === 3 ? <>
                                    <span className='sub-title'>Borrow HW</span> <br />
                                    <span style={{ color: '#8C8C8C' }}>Replace, Return</span>
                                </> : null}
                                {Template === 4 ? <>
                                    <span className='sub-title'>Install New HW</span> <br />
                                    <span style={{ color: '#8C8C8C' }}>Install</span>
                                </> : null}
                                {Template === 5 ? <>
                                    <span className='sub-title'>Replace HW</span> <br />
                                    <span style={{ color: '#8C8C8C' }}>Replace, Collect</span>
                                </> : null}
                                {Template === 6 ? <>
                                    <span className='sub-title'>Return HW / Move HW: Different Building</span> <br />
                                    <span style={{ color: '#8C8C8C' }}>Collect</span>
                                </> : null}
                            </Col>
                            <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                                {showCancel(data || [])}
                            </Col>
                        </Row>
                        <br />
                        <Spin spinning={isSpinning}>
                            <div style={{ padding: 10, overflow: 'auto' }}>
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    rowKey='jobNo'
                                    expandedRowRender={props.permission || !isHaveJobs ? undefined : expandedRowRender}
                                    pagination={false}
                                // expandedRowKeys={[currentJobNo]}
                                />
                            </div>
                        </Spin>
                    </div>}
        </>
    )
}

const RelateJobsWithForm = Form.create<Props>({ name: 'JobForm' })(RelateJob)

export default connect(mapStateToProps,
    {
        getAllJob, addJob
    })(RelateJobsWithForm)
